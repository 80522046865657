@value classes: "../../themes/theme.module.css";
@value h1Desktop, h5Desktop from classes;

.root {
    position: relative
}

.title {
    font-weight: bold;
    color: white;
    white-space: pre-line;
}

.subtitle {
    font-weight: 600;
    color: white;
}

.text {
    position: absolute;
    top: 35%;
    left: 7%;
}

@media (max-width: 800px) {
    .text {
        position: absolute;
        top: 7%;
        left: 7%;
    }
}
