@value classes: "../../themes/theme.module.css";
@value grey100, lake500, lake600 from classes;

.Button {
  color: lake500;
  font-size: 19;
  font-weight: 700;
}

.Button:hover {
  background-color: grey100;
  color: lake600;
}