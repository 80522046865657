/* Colors */
/* Primary */
@value lake900: #003E4B;
@value lake800: #004F5F;
@value lake700: #006073;
@value lake600: #0A7287;
@value lake500: #00829B;
@value lake400: #049AB7;
@value lake300: #3cdED7;
@value lake200: #5ADCF5;
@value lake100: #80EAFF;
@value lake50: #BFF4FF;
@value lake25: #DFF9FF;
@value moss900: #003A00;
@value moss800: #004E00;
@value moss700: #006200;
@value moss600: #007600;
@value moss500: #008A05;
@value moss400: #06AC0C;
@value moss300: #24CA2A;
@value moss200: #42E848;
@value moss100: #80FF84;
@value moss50: #D9FCDA;
@value moss25: #ECFDED;

/* Neutral */
@value black: #000000;
@value white: #FFFFFF;
@value grey900: #262626;
@value grey800: #424242;
@value grey700: #5C5C5C;
@value grey600: #767676;
@value grey500: #949494;
@value grey400: #B2B2B2;
@value grey450: #BDBDBD;
@value grey300: #CCCCCC;
@value grey200: #E5E5E5;
@value grey100: #F2F2F2;
@value grey50: #FAFAFA;

/* States */
@value red600: #C20000;
@value red500: #D60000;
@value red400: #EB0000;
@value red50: #FFE4DE;
@value orange800: #AD4800;
@value orange700: #C75300;
@value orange600: #D3691B;
@value orange500: #FF9E1B;
@value orange400: #FED37E;
@value orange50: #FFE9BD;
@value lemon: #F5FFB5;

/* Headers */
/* H1 — Mobile; */
.h1Mobile{
  font-family: "Merriweather", serif;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.5px;
  text-align: left;
  color: black;
}

/* H1 — Tablet; */
.h1Tablet{
  font-family: "Merriweather", serif;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -1.5px;
  text-align: left;
  color: black;
}

/* H1 — Desktop; */
.h1Desktop{
  font-family: "Merriweather", serif;
  font-size: 56px;
  line-height: 70px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

/* H2 — Mobile; */
.h2Mobile{
  font-family: "Public Sans", sans-serif;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

/* H2 — Tablet; */
.h2Tablet{
  font-family: "Public Sans", sans-serif;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.5px;
  text-align: left;
  color: black;
}

/* H2 — Desktop; */
.h2Desktop{
  font-family: "Public Sans", sans-serif;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.5px;
  text-align: left;
  color: black;
}

/* H3/Regular — Mobile; */
.h3Mobile {
  font-family: "Merriweather", serif;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

/* H3 — Tablet; */
.h3Tablet {
  font-family: "Merriweather", serif;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

/* H3 — Desktop; */
.h3Desktop{
  font-family: "Merriweather", serif;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

/* H4 — Mobile; */
.h4Mobile{
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  text-align: left;
  color: black;
}

/* H4 — Tablet; */
.h4Tablet{
  font-family: "Oswald", sans-serif;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: 0.15px;
  text-align: left;
  color: black;
}

/* H4 — Desktop; */
.h4Desktop{
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  line-height: 47px;
  letter-spacing: 0.25px;
  text-align: left;
  color: black;
}

/* H5 — Mobile; */
.h5Mobile {
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  color: black;
}

/* H5 — Tablet; */
.h5Tablet {
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  color: black;
}

/* H5 — Desktop; */
.h5Desktop{
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  color: black;
  font-weight: 500;
}

/* H5 — Desktop heavy; */
.h5DesktopHeavy{
  font-family: "Merriweather", sans-serif;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  color: black;
  font-weight: 700;
}

/* H6 — Mobile; */
.h6Mobile {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  color: black;
}

/* H6 — Tablet; */
.h6Tablet {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  display: flex;
  color: black;
}

/* H6 — Desktop; */
.h6Desktop{
  font-family: "Oswald", sans-serif;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.25px;
  text-align: left;
  display: flex;
  font-weight: 500;
  color: black;
}

.h5Tablet.light, .h5Desktop.light, .h6Tablet.light, .h6Desktop.light{
  font-weight: 300;
}
.h5Mobile.regular, .h5Tablet.regular, .h5Desktop.regular{
  font-weight: 600;
}
.h2Tablet.regular, .h2Desktop.regular, .h4Mobile.regular,  .h4Tablet.regular, .h4Desktop.regular, .h6Mobile.regular, .h6Tablet.regular, .h6Desktop.regular {
  font-weight: 500;
}
.h2Desktop.heavy, .h5Mobile.heavy, .h5Tablet.heavy, .h5Desktop.heavy{ 
  font-weight: 800;
}

/* Body */
/* Body Large — Mobile; */
.bodyLargeMobile{
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
  color: black;
}

/* Body Large — Tablet; */
.bodyLargeTablet{
  font-family: "Public Sans", sans-serif;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  color: black;
}

/* Body Large — Desktop; */
.bodyLargeDesktop{
  font-family: "Public Sans", sans-serif;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0px;
  color: black;
}

/* Body Base; */
.bodyBase{
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #09101D;
}

/* Body Small; */
.bodySmall, .bodySmallDim, .bodySmallDimStalic{
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
}

.bodySmall{
  color: #09101D;
}

.bodySmallDim, .bodySmallDimItalic{
  color: #767676; /* grey600 */
}

.bodySmallDimItalic{
  font-style: italic;
}

/* Caption; */
.caption{
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #09101D;
}


.caption.heavy, .bodySmall.heavy{
  font-weight: 600;
}

.caption.heavy{
  color: black;
}

/* SmallCaps; */
.smallCaps{
  font-family: "Public Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
}

/* TinyCaps; */
.tinyCaps{
  font-family: "Public Sans", sans-serif;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: black;
}

/* Button/Link - Large; */
.actionTextLarge{
  font-family: "Public Sans", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.32px;
  text-align: left;
  text-transform: none;
}

.buttonTextLarge{
  margin: 6.5px 0;
}

/* Button/Link Small; */
.actionTextSmall{
  font-size: 14px;
  font-family: "Public Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.16px;
  text-align: left;
  text-transform: none;
}

.buttonTextSmall{
  margin: 4px 0;
}

/* general styles on font weights - place after typography classes */
.light{
  font-weight: 200;
}

.regular{
  font-weight: normal;
}

.heavy{
  font-weight: bold;
}

.roundButton{
  border-radius: 300px !important;
}

.avatar {
  color: lake600;
  background-color: white;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 19px;
  height: 30px;
  width: 30px;
  margin: 3px 3px 3px 19px;
}

