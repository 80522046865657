@value classes: "../../themes/theme.module.css";
@value bodySmall, bodySmallDimItalic, h6Desktop from classes;

.item {
    background-color: "white";
}

.item:hover {
    background-color: #F2F2F2;
}

.listHeader, 
.menuHeader {
    padding-bottom: 0px;
    color: #747474;
}

.listTitle {
    padding-bottom: 26px;
    padding-top: 0px;
}

.listTitle::after {
    width: 90%;
    content: "";
    position: absolute;
    top: 47px;
    left: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.listTitle2 {
    padding-top: 0px;
}

.listTitleLast {
    width: 90%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.listItem {
    width: 456px;
    margin: 0px 19px 0px 27px;
}

.listItem:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.listItemText {
    flex-basis: 50%;
}

.listItemTitle {
    text-align: right;
    font-weight: bold;
    margin-right: 24px;
}

.launch {
    margin-right: 10px;
    color: #00829B;
}

.launchDisabled {
    margin-right: 10px;
    opacity: 0.5;
}

.h6Desktop.menuTitle {
    opacity: 1;
    padding-top: 0
}
