@value classes: "../../../themes/theme.module.css";
@value grey500, orange50, orange600, red500, moss500, lake500, h5Desktop, bodySmall, regular, light from classes;

.main, .mainBorder{
  width: 100%;
  height: 100%; 
  line-height: 20px;
  word-wrap: break-word;
  box-sizing: border-box;
  font-size: 18px;
}

.mainBorder{
  background: rgba(196, 196, 196, 0.25);
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  padding: 20px;
  user-select: none;
  white-space: pre-wrap;
  max-height: 450px;
  max-width: 780px;
  overflow-y: auto;
}

.flex{
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 32px;  
}

.actionButtons {
  justify-content: flex-end;
}
.learnMore {
  justify-content: flex-start;
  flex: 1;
  margin-top: 10px;
}

.flexTop{
  display: inline-flex;
  align-items: top;
}

.flexText{
  display: inline-flex;
  flex-direction: column;
}

.fileName {
  font-weight: bold;
  font-style: italic;
  color: rgb(21, 0, 255);
}

.icon{
  padding: 20px 60px 0px 40px;
}

.warningIcon {
  vertical-align: middle;
  font-size: 30px;
  padding-right: 3px;
  padding-bottom: 3px;
}

.button {
  white-space: nowrap;
  padding: 8px 16px;
}

.logo {
  height: 120px;
  width: 65px;
  transform: rotate(10deg);
}

.smlogo {
  margin: 0px 20px 0px 20px;
  height: 65px;
  width: 37px;
  transform: rotate(8.14deg);
  opacity: 0.5;
}

.leftSpaced{
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 32px;
}


h2.header{
  font-family: Public Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 28px;
}

div.text{
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 151.7%;
}

.infoHeader{
  font-family: Public Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 28px;
}
.infoText{
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 151.7%;
}

.subTitle {
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.notePadIcon, .copyIcon {
  margin-right: 16px;
}

.notePadIcon {
  background-color: #fff;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.learnMoreText {
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;  
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  color: #005EA2;
}

.largeCentered {
  width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.centeredDiv {
  margin: 4px 30px 20px 30px;
}

.block {
  margin-top: 30px;
}

.largeListHead {
  margin-right: 20px;
  color: #9FD7E2;
  height: 54px;
  width: 54px;
}

.withlogo{
  display: flex;
}

.logo{
  margin: 0px 20px 0px 20px;
  opacity: 0.5;
  height: 5%;
  width: 5%;
}

.buttonContainer{
  display: flex;
  justify-content: flex-end;
  align-items: right;
  margin: 32px 44px 32px 0px;
}

.buttonContainer>Button{
  margin: 5px;
}

.orangeButton, .orangeBorderButton{
  min-width: 120px;
  padding-left: 20px;
  padding-right: 20px;
}

.orangeButton{
  background-color: orange600;
  color: #fff;
}

.orangeBorderButton{
  border: 2px solid orange600;
  background-color: #fff;
  color: orange600;
}

.orangeBorderButton:hover{
  color: #fff;
  border: 1px solid rgba(0,0,0,0);
}

.content{
  flex-grow: 1;
}

.title {
  margin-bottom: 20px;
}

.header{
  margin: 0px 0px 32px 0px;
}
.text{
  margin: 0px 44px 0px 0px;
}

.globusAccountNameFootNote {
  font-family: Source Sans Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: grey500;
  margin-left: 12px;
  margin-top: 4px;
}

.globusAccountNameLabelNormal, .globusAccountNameLabelError {
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  
}

.globusAccountNameLabelNormal {
  color: lake500;
}

.globusAccountNameLabelError {
  color: red500;
}
