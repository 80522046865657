@value classes: "../../themes/theme.module.css";
@value grey500 from classes;

.clickable {
  cursor: pointer;
}

.table {
  border-collapse: collapse;
  /* border-bottom: solid 1px grey500; */
}

.nameHeader:hover {
  background-color: #dddddd;
  cursor: pointer;
}

.paper {
  border-radius: 0 0 4px 4px;
}

.tableContainer {
  height: 100%;
  border-radius: 0 0 4px 4px;
}

.row, .rowExpand{
  height: 120px;
  border: 1px solid grey500;
  border-bottom: none;
  background-color: #f2f2f2;
}

.row:first-child {
  border-top: none;
}

.rowExpand{
  border-bottom: 1px solid grey500;
}

.row:hover,  .rowExpand:hover {
  background-color: #fff !important;
  transform: scale(1);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.rowExpand {
  background-color: #FFFFFF;
}
