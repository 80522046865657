/* class to have overflow: auto div to always show the scrollbar - only works in Chrome */
div.showScrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

div.showScrollbar::-webkit-scrollbar:vertical {
  width: 11px;
}

div.showScrollbar::-webkit-scrollbar:horizontal {
  height: 11px;
}

div.showScrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

/* to show a static scrollbar in kebabs (Only worked for Chrome)*/
.MuiPopover-paper::-webkit-scrollbar {
  -webkit-appearance: none;
}

.MuiPopover-paper::-webkit-scrollbar:vertical {
  width: 11px;
}

.MuiPopover-paper::-webkit-scrollbar:horizontal {
  height: 11px;
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.tipSize {
  font-size: 18px;
}

/* My Project Manage Access table's Users with Access colunm - to show scrollbar on multiple users */
#data-grid-collaborators::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
#data-grid-collaborators::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.filePattern .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #00829B;
}

/* FilterGroup ListItemButton caret icon color and size*/
.FilterGroupListItemButton .MuiSvgIcon-root {
  color: #0F9AB7;
  font-size: 28px;
}

/* AppContainer fringe popover: remove shadow ... */
#FringePopover .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  box-shadow: none;
  border: solid 1px #000;
}

/* mui Button text nowrap */
/* button.MuiButton-text.MuiButton-textPrimary.MuiButton-root.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.makeStyles-buttonIcon-62.css-57g0c9-MuiButtonBase-root-MuiButton-root */
button.MuiButton-text.MuiButton-textPrimary {
  white-space: nowrap;
  text-overflow: ellipsis;
}