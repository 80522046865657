@value classes: "../../../themes/theme.module.css";
@value lake25, lake50, lake100, lake500 from classes;

.pageSizeNum {
  /* font-family: 'Source Sans Pro'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 143%;

  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.pagingLabel {
  margin-right: 8;
  position: "relative";
  top: 2;

  /* from https://www.figma.com/file/SXaBx4bkArxAi6u3XxgPuG/Data-Portal-Design-Concepts?node-id=3981%3A110098 */
  /* font-family: 'Source Sans Pro'; */
  /* font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px; */
  /* identical to box height, or 125% */

  /* color: #00829B; */
}