@value colors: "../../../themes/theme.module.css";
@value black, orange600, moss500 from colors;

.icon {
  vertical-align: middle;
  font-size: 30px;
  padding-right: 3px;
  padding-bottom: 3px;
}

.root {
  flex-grow: 1;
  min-width: 300px;
}

.boldText {
  font-weight: bold;
  vertical-align: middle;
  display: inline;
  color: black;
}

.text {
  padding-top: 2px;
  vertical-align: middle;
  display: inline;
  color:black;
}

.infoIcon {
  vertical-align: middle;
  font-size: 28px;
  padding-left: 8px;
}
