/* 
@value classes: "../../../themes/theme.module.css";
@value lake500 from classes; 
*/
/* can't use lake500 in this file */

/* toolbar icon style */
.data-grid-toolbar-icon svg.MuiSvgIcon-root {
  color: #00829B; /* lake500 */
  width: 16px;
  height: 16px;
}

/* toolbar text style */
.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButtonBase-root.data-grid-toolbar-icon {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #00829B; /* lake500 */
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButtonBase-root.data-grid-toolbar-icon:hover {
  font-weight: 800;
  color: rgb(8, 161, 192);
}

/* COLUMNS UI switch knob icon: need descendant selector to overwrite the original style */
.MuiDataGrid-panelContent>.MuiDataGrid-columnsPanel>.MuiDataGrid-columnsPanelRow>.MuiFormControlLabel-root>.MuiSwitch-root>span.Mui-checked {
  color: #00829B /* lake500 */
}

/* COLUMNS pulldown UI footer button text: need descendant selector to overwrite the original style */
.MuiDataGrid-paper>.MuiDataGrid-panelWrapper>.MuiDataGrid-panelFooter>button{
  color: #00829B /* lake500 */
}

/* COLUMNS pulldown UI top input hightlight small label */
.MuiDataGrid-paper>.MuiDataGrid-panelWrapper>.MuiDataGrid-panelHeader>.MuiTextField-root>label.MuiInputLabel-animated.Mui-focused {
  color: #00829B /* lake500 */
}

/* FILTERS pulldown UI input highlight small label: need descendant selector to overwrite the original style */
.MuiDataGrid-panel>.MuiDataGrid-paper>.MuiDataGrid-panelWrapper>.MuiDataGrid-panelContent>.MuiDataGrid-filterForm>.MuiFormControl-root label {
  color: #00829B /* lake500 */
}

/* DENSITY pulldown UI item icon color */
.MuiListItemIcon-root svg {
  color: #00829B /* lake500 */
}

/* checkbox in datagrid table */
span.MuiButtonBase-root>svg{
  color: #00829B /* lake500 */
}

