@value colors: "../../themes/theme.module.css";
@value lake25, lake50, lake400, lake500, lake600, grey100, grey300, grey600, grey500 from colors;

.boxIcon {
  font-size: 28px;
}
.bentoBoxGrid{
  display: flex;
  padding-right: 12px; 
  /* to match downloader's Toolbar padding-right */
}

.roundBox, .roundBoxDisabled {
  display: flex;
  flex-grow: 4; 
  background-color: lake25;
  margin: 12px 15px 12px 25px;
  border-radius: 300px;
  height: 48px;
}
.roundBoxDisabled {
  background-color: grey100;
}

.selectBox, .selectBoxDisabled {
  display: flex;
  flex-grow: 1;
  padding: 8px 16px 8px 16px; 
  border-radius: 300px;
  background-color: lake25;
  border: 2px solid lake25;
  color: lake600 !important;
  min-width: 200px;  
}
.selectBoxDisabled {
  color: grey600 !important;
  background-color: grey100;
  border: none;
}

.searchBox, .searchBoxDisabled  {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 15;
  border-radius: 300px;
  background-color: lake25;
  border: 2px solid lake25;
  padding: 8px 16px 8px 16px; 
  min-width: 200px;
  margin-top: 0px;
}
.searchBoxDisabled {
  background-color: grey100;
  border: none;
}

.rootBox {
  display: flex;
  flex: 5;

}

.searchBox:hover, .selectBox:hover {
  border: 2px solid lake50;
  background-color: lake50;
  z-index:1;
}

.searchBox:active, .selectBox:active, .searchBox:focus-within, .selectBox:focus, .selectBox.listOpen{
  background-color: white;
  border: 2px solid lake400;
  z-index:1;

}

.divider {
  font-size: 32px;
  color: rgba(10, 114, 135, 0.15);
  padding-bottom: 8px;

}
.dividerHidden {
  font-size: 32px;
  color: lake25;
  padding-bottom: 8px;

}

.toggleIcon {
  display: flex;
  font-size: 28
}

.searchIcon {
  color: lake600;
  width: 30px;
  height: 30px;
}

.clearIcon {
  color: #c4c4c4;
}

.clearIcon.hovered {
  color: grey600
}

.rootBox {
  border: 1px solid #c4c4c4;
  margin: 12px 120px;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
}

.disabled {
  background-color: "#949494",
}

.advancedSearchButton{
  color: lake500;
  text-align: center;
  margin: 0px;
  padding: 0px;
  /* Button/Large */
  font-family: "Public Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 100% */
  letter-spacing: 0.32px;

  /* cursor: pointer; */
}

.returnToBasic {
  /* text-transform: none; */
  color: #FF0000;
}

.returnToBasic:hover {
  color: lake600;
}
