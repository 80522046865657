@value classes: "../../themes/theme.module.css";
@value bodySmall, bodyBase, red500, orange700, moss500, lake500, grey600, grey300, black from classes;

.downloaderBox, .downloaderBoxInCart {
  border: 1px solid grey300;
  background-color: white;
  display: fixed;
  box-shadow: 0px 1px 3px 0px rgb(0, 0, 0 / 30%);
  border-radius: 4px;
}

.titleBox {
  margin: 30px 0px;
}

.searchInfoBox{
  width: 100%;
  display: flex;
  justify-content: space-between; 
  padding-right: 40px;
}


.searchInfoBox .totalCount, .searchInfoBox {
  color: grey600;
}

.searchInfoBox .link{
  right: 8px;
}

.link {
  color: lake500;
}

.filterBox {
  border: 1px solid grey300;
  background-color: white;
  border-radius: 4px 4px 0 0;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
}

.appBar {
  border: 1px solid #c4c4c4;
  background-color: white;
  display: fixed;
  width: "100%";
}

.tableBox {
  margin: 0px 0px 0px;
  border-radius: 0 0 4px 4px;
}

.rootBox {
  border: 1px solid #c4c4c4;
  margin: 12px 24px;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  min-width: 500px;
}

.PageBranchBodyBox {
    minWidth: 800;
    marginTop: 20;
}
