@value classes: "../../themes/theme.module.css";
@value h2Desktop, bodyLargeDesktop, bodySmall, bodyBase, lake400, lake500, grey900, grey800, heavy from classes;

.middleBox {
  max-width: 1440px;
  min-width: 800px;
  margin: auto;
}

.mainBox {
  max-width: 1440px;
  min-width: 800px;
  margin: auto;
}

.middleCards {
  width: 100%;
  padding: 40px 0px;
}

.middleCards {
  display: flex;
  justify-content: center;
  padding-top: 0px;
}

.linkInCard {
  text-decoration: underline;
  color: #09101D;
  font-weight: normal;
}

.linkLake400 {
  color: lake400;
}

.h2Desktop.ourdataTitleText{
  margin-top: 60px;
  margin-bottom: 20px;
  font-weight: 500;
  color: grey800;
}

.bodyLargeDesktop.ourdataText{
  color: grey900;
}

.bodyLargeDesktop.ourdataBullet{
  margin-top: 42px;
  color: grey900;
}

.advanceSearchLabel{
  display: flex;
  width: 199px;
  flex-direction: column;
  align-items: flex-start;
  color: lake500;
  cursor: pointer;

  font-family: "Public Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 100% */
  letter-spacing: 0.32px;
}