@value colors: "../../themes/theme.module.css";
@value white, lake25, lake400, lake600, grey400, bodyLargeDesktop from colors;

.searchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 24px;
    position: static;
    height: 66px;
    left: 0px;
    top: 0px;
    background: white;
    border: 2px solid lake400;
    box-sizing: border-box;
    border-radius: 360px;
    margin-top: 0px;
  }

.searchBar:hover {
    filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.25));
}

.searchBar:focus-within {
    filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.25));
}

.autoComplete {
    height: 66px;
}

.searchIcon {
    color: lake600;
    width: 40px;
    height: 40px;
}

.clearIcon {
    color: grey400;
    width: 40px;
    height: 40px;
}

