@value colors: "../../themes/theme.module.css";
@value black, orange600, lake500, moss500, red500 from colors;
.toolBar {
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.imageBox {
  display: flex;
  margin-right: 46px;
  color: #747474;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.box, .boxUser {
  display: flex;
  margin-right: 1.25rem;
  white-space: nowrap;
  font-size: 12px;
}
.boxUser {
  margin-left: 4px;
}

.box {
  text-decoration: underline;
  color: #747474;
}

.linkFont {
  font-family: Public Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #747474;
}

.linkFont:hover {
  color: orange;
  background-color: white;
}

.link {
  display: flex;
  padding: 0;
  border: none;
  background: none;
}

.link:hover {
  color: orange;
  background-color: white;
}

.infoIcon {
  margin-left: 4px;
  margin-top: -7px;
}

.tooltip {
  min-width: 300px;
}

.tooltipText {
  font-size: 6px;
}

.notifyIcon{
  opacity: 0.6;
}

.styledBadge {
  margin-left: 6px;
  cursor: pointer;
}

.styledBadge:hover > .notifyIcon{
  fill: #1D9FBB;
  opacity: 1;
}

.userIcon {
  height: 34px;
  width: 34px;
  margin-left: 6px;
  opacity: 0.2;
}

.menuTitle {
  font-family: Public Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  padding: 0px 6px;
}

/* .userAvatar {
  cursor: pointer;
  background-color: #737373;

}

.userAvatar:hover {
  background-color: #1D9FBB;
} */

.divider {
  color: black;
}

.userMenuText{
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  color: #302E2E;
  white-space: nowrap;
}

.userMenuUserName{
  text-align: left;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #302E2E;
}

.userMenuUserEmail{
  font-family: Public Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #302E2E;
}

.notifyNoNewDataIcon{
  width: 100%;
  color: #949949;
  width: 44px;
  height: 54px;
  margin-top: 20px;
  margin-left: 110px;
}

.notifyNoNewDataText{
  width: 100%;
  font-family: Public Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #737373;
}

.notifyNoNewDataSubtext{
  width: 100%;
  font-family: Public Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
}

.spacer {
  padding-right: 18px !important;
}
