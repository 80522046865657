/* JDP-940: Genome name apply global "h3" but a different color.  */
@value colors: "../../../themes/theme.module.css";
@value lake500, lake600, lake700, orange700 from colors;

.h3Color {
    color: #262626;
}

.clickable {
    cursor: pointer;
}

.kebab {
    height: 48px;
    width: 48px;
    color: #949494;
}

.kebabHidden {
    visibility: hidden;
}

.kebab:hover {
    color: #0F9AB7; 
}

.launch {
    margin-right: 10px;
    color: #00829B;
}

.addToCartIcon24, .removeFromCartIcon24 {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.addToCartIcon32, .removeFromCartIcon32 {
    cursor: pointer;
    width: 32px;
    height: 32px;
}

.addToCartIcon32, .addToCartIcon24 {
    color: lake600;
}

.removeFromCartIcon32, .removeFromCartIcon24 {
    color: orange700;
}
