@value theme: "../../../themes/theme.module.css";
@value bodySmall, lake500, lake25 from theme;

.boxIcon {
  font-size: 28px;
  color: #0F9AB7;
}
.countAvatar {
  background-color: lake500;
  color: #ffffff;
  width: 24px;
  height: 24px;
  font-weight: 700;
  font-size: 14px;
}

.filterIconBox {
  width: 36px;
  margin-top: 12px;
}

.filtersLabelBox {
  display: flex;
  padding: 24px 8px 0px 0px;
  width: 166px;
  white-space: nowrap;
}

.gridContainer {
  min-height: 76px;
  margin: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.gridItem {
  min-width: 235px;
}

.gridItemLeft {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.filterInput:hover fieldset,
.filterInput:focus fieldset,
.filterInput:focus-within fieldset,
.filterInput:focus-visible fieldset,
.filterInput:active fieldset,
.filterInputFilled fieldset, 
.gridItem .filterInputFilled:hover fieldset {
  border: 1px solid lake500 !important;
}

.labelText {
  font-size: 18px;
  font-weight: 400;
  color: #302e2e;
}

.listItem {
  font-weight: bold;
  color: #302e2e;
  border-top: 1px solid #e5e5e5;
  margin: 0px 18px;
  padding: 0px;
}

.listItem:active,
.listItem:focus,
.listItem:hover {
  background-color: lake25;
}

/* the filter pulldown fixed width */
/* .listbox {
  width: 350px;
}

.paper {
  width: 350px;
} */
.listbox, .paper {
  width: 100%;
}

.rootBox {
  width: 100%;
  justify-content: flex-end;
  padding: 8px 16px;
  border-bottom: 1px solid #DDDDDD;
}

@media only screen and (min-width: 1250px) {
  .rootBox {
    display: flex;
  }
}

.rootBox.filterFooter {
  padding: 29px 0px 25px;
  border-bottom: none;
}

.chipContainer {
  overflow: auto;
  flex-wrap: wrap;
  flex: 2 200px;
}

.clearAllBox {
  width: fit-content;
  white-space: nowrap;
  min-height: 32px;
  margin: 0 16px;
  display: inline-flex;
}

.clearAllText {
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}

.filterChip {
  color: #00829b;
  background-color: #ffffff;
  border-color: #00829b;
  font-size: 14px;
  font-weight: 600;
  margin: 4px;
}

.filterChipBox {
  font-weight: bold;
  font-size: 20px;
  color: #302e2e;
}


.filterHelpBox {
  min-width: 188px;
  align-self: flex-end;
}

.filterHelpLink {
  color: lake500;
}

/* .filterHelpIcon {
  color: lake500;
  vertical-align: middle;
  margin: 0 4px;
} */

.bodySmall.filterHelpText {
  display: inline-block;
}

