.copyrightText {
  color: #2e2e2e;
  font-size: 0.75rem;
}

.threeLogo {
  float: right;
  padding-right: 1rem;
  padding-top: 0.75rem;
  height: 100%;
  cursor: pointer;
  align-items: center;
}

.threeLogo > * {
  padding-left: 24px;
}

.facebookIcon {
  color: #3b5999;
  height: 70%;
}

.gridContainer {
  padding-left: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.linkFont {
  font-size: 0.75rem;
}

.title {
  color: black;
}

.toolBarFix, .toolBar {
  bottom: 0;
  padding: 0;
  background-color: rgb(233, 233, 233);
  width: 100%;
}

.toolBarFix {
  position: fixed;
}

.twitterIcon {
  color: #00acee;
  padding-left: 0.5rem;
  height: 70%;
}

.versionText {
  color: #2e2e2e;
  font-size: 0.65rem;
  padding-top: 0.35rem;
}

.ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.li {
  display: flex;
  margin-right: 1.25rem;
}
