@value colors: "../../../../themes/theme.module.css";
@value orange50, orange600, moss400, red500, moss500, lake500 from colors;

.title{
  font-size: 1.25rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.controlContainer{
  margin: 12px;
  padding: 8px 44px;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 32px 50px;
}

.buttonLeft {
  margin-right: auto;
}

.buttonRight {
  margin-left: auto;
}

.modalContent{
  margin: 16px 25px 0px 21.6px;
}

.actions{
  margin: 0;
}

.footer{
  margin: 0px 25px 0px 0px;
}

.buttonContainer>Button{
  margin: 5px;
}

.orangeButton, .orangeBorderButton{
  min-width: 120px;
  padding-left: 20px;
  padding-right: 20px;
}

.orangeButton{
  background-color: orange600;
  color: #fff;
}

.orangeButton:hover{
  background-color:moss400;
}

.orangeBorderButton{
  border: 2px solid orange600;
  background-color: #fff;
  color: orange600;
}

.orangeBorderButton:hover{
  background-color:moss400;
  color: #fff;
  border: 1px solid rgba(0,0,0,0);
}