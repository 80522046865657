@value colors: "../../../themes/theme.module.css";
@value grey600, lake500 from colors;
.statusIcon {
    vertical-align:  middle;
    margin-right: 3px;
}

.dataCell {
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #262626;
}

.kebab {
    height: 36px;
    width: 36px;
    color: #949494;
    cursor: "pointer",
}
.kebab:hover {
    color: #0F9AB7; 
}

.kebabContainer {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    top: 2px;
    margin-left: 10px;
}