@value colors: "../../themes/theme.module.css";
@value black, orange600, lake500, moss500, red500 from colors;
@value theme: "../../themes/theme.module.css";
@value h5DesktopHeavy, h5Desktop, bodySmall from theme;

.infoBar{
  margin-bottom: 8px;
  padding: 8px 120px;
  border: none;
  border-radius: 0px;
  display: flex;
  align-items: center;
}

.infoBar.inputAlert{
  width: 320px;
  padding: 6px 8px 6px 16px;
}

.infoText{
  padding:0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: black;
  display: flex;
}

.background {
  padding: 8px;
  background-color: rgba(244, 173, 34, 0.1);
  width: 100%;
}

.subtitle{
  padding-left: 8px;
  font-size: 16px;
}

.warningContainer {
  border-left: 8px solid #F4AD22;
  padding-left: 12px;
  padding-top: 6px;
}