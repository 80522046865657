.gridContainer {
  margin: auto;
  margin-top: 150px;
  max-width: 800px;
  padding: 40px 40px 20px 40px;
  border: 3px solid #50840F;
  border-radius: 4px;
}

.jgiLogo {
  width: 70px;
  height: 123px;
}

.nerscLogo {
  width: 96px;
  height: 96px;
}

.subtitle {
  font-weight: bold;
}

.text {
  font-size: 14px;
}

.downtime {
  font-size: 14px;
  font-weight: bold;
}

.browserIcon {
  min-width: 20px;
}
