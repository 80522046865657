@value theme: "../../../themes/theme.module.css";
@value h5DesktopHeavy, h5Desktop, bodySmall from theme;

.background {
  padding: 8px;
  background-color: rgba(244, 173, 34, 0.1);
  width: 100%;
}

.warningContainer {
  border-left: 8px solid #F4AD22;
  padding-left: 12px;
  padding-top: 6px;
}

