@value colors: "../../themes/theme.module.css";
@value lake500 from colors;
.dataRow {
  height: 60px;
}

.headerRow {
  border: none;
}

.headerColumn, .headerColumnSort {
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  background-color: rgba(255, 255, 255, 0.38);
  border-bottom: 2px solid #999;
  height: 60px;
}

.headerColumnSort {
  cursor: pointer;
}

.headerColumnSort:hover > img { opacity: 1; }

.headerColumnSort:hover {
  background-color: rgba(196, 196, 196, 0.38);
}

.headerSorted {
  background-color: rgba(226, 226, 226, 0.38);
}

.sortDes, .sortAsc, .sortable {
  vertical-align: middle;
  width: 28px;
  height: 28px;
  margin-left: 2px;
}

.sortDes {
  transform: rotate(180deg);
}

.sortable {
  opacity: .2;
}

.tableContainer {
  height: 100%;
  box-shadow: inset 0px 8px 12px -4px rgba(0, 0, 0, 0.7),
    inset 0px -18px 14px -18px rgba(0, 0, 0, 0.7);
}

.infoIcon {
  vertical-align: middle;
  font-size: 28px;
  padding-left: 8px;
}

.checkboxCell {
  padding-left: 32px;
  padding-right: 8px;
  width: 48px;
}

.rowEven, .rowQuickDownload, .rowArchived, .rowQueued {
  margin-left: 24
}

.rowEven {
  background-color: rgba(229, 229, 229, 0.5);
}

.rowOdd {
  background-color: rgba(255, 255, 255, 0.5);
}

.rowQuickDownload {
  background-color: #deffd3;
  border-bottom: 1px solid #b4e7a4;
}

.rowArchived {
  background: #f5ffb5;
  border-bottom: 1px solid #d8e681;
}

.rowQueued {
  background: #f5ffb5;
}
