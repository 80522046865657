.title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.7
}

.message {
    line-height: 1.3
}

.gridContainer {
	padding-left: 120px;
    padding-right: 120px;
    padding-top: 20px;
}

.background {
	padding: 8px;
    background-color: rgba(244, 173, 34, 0.1);
    width: 100%;
}

.warningContainer {
    border-left: 8px solid #F4AD22;
	padding-left: 12px;
    padding-top: 6px;
}

