@value classes: "../../../themes/theme.module.css";
@value h4Desktop. h5Desktop, h3Desktop from classes;


.mainTitle {
  font-size: 32px;
  /* color: rgb(130, 228, 103); */
  padding-bottom: 12px;
}

.paraTitle {
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding: 16px 0px;
}

.subTitle {
  font-family: "Public Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  padding: 8px 0px;
}