@value colors: "../../../themes/theme.module.css";
@value lake400, grey300, moss300, red500 from colors;

.BellIcon {
  transform: scale(1.2);
}

.DotBadge {
  transform: scale(1.2);
}

.NoticeContent {
  width: 260px;
  margin: 0px 20px;
}

.NoticeExpire {
  font-style: italic;
  padding-right: 20px;
  color: #aaa;
}

.MainHeader{
  padding: 0px 14px;
  font-weight: bold;
}

.NoticeTypeIcon{
  margin-right: 6px;
  margin-top: 2px;
}