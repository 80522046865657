@value classes: "../../themes/theme.module.css";
@value lake400, lake500, lake600, grey500, h4Desktop, h6Desktop, bodyBase, bodySmall, actionTextLarge, heavy, grey900 from classes;


.borderless {
  min-height: 123px;
  padding: 8px 8px 24px 8px;
  box-shadow: none;
  background-color: none;
}

.h4Desktop.cardTitle {
  margin-bottom: 40px;
  /* height: 48px; */
  line-height: 47px;
}

.smallcardTitle {
  color: grey900;
}

.cardText {
  margin-bottom: 40px;
  line-height: 27px;
}

.cardContent {
  flex: 1 1 auto;
  flex-direction: column;
}

.cardButton {
  justify-content: flex-end;
  white-space: nowrap;
}

.cardIcon {
  padding-top: 16px;
  color: lake400;
  width: 35px;
  height: 35px;
}

.cardIconLarge {
  padding-top: 16px;
  color: lake400;
  width: 40px;
  height: 40px;
}

.linkButtonContainer {
  display: flex;
  align-items: center; 
}

.linkButton {
  box-sizing: border-box;

  font-family: Public Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 19px;

  border-radius: 4px;

  display: flex;
  align-items: center; 
  width: auto;
  padding: 14px 40px;
  color: #00829B;
}

/* hover  */
.linkButton:hover > .linkButtonIcon {
  color: #00829B;
}

.linkButton:hover {
  background-color: #efefef;
}

.linkButtonIcon {
  color: #999;
  padding-top: 1px;
}

.linkButtonIcon:hover {
  color: #00829B;
}

.flexRight {
  justify-content: flex-end;
}

.borderless {
  border: none;
  box-shadow: none;
  background-color: RGB(0,0,0,0);
  padding: 0px;
}

.withIconContent {
  padding-left: 6px;
  padding-top: 14px;
}
